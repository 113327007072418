// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue'

import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import App from './App'
import router from './router'

import i18n from './i18n'

const app = createApp(App)

app.use(router)
app.use(VueReCaptcha, {
    siteKey: '6LdRdCIfAAAAAEPuftX1As1npBbTnS8QPOrkS7xC',
    loaderOptions: {
        renderParameters: {
            autoHideBadge: true
        }
    }})
app.use(VueSweetalert2)

app.use(i18n())

app.mount('#app')