import { createRouter, createWebHistory } from 'vue-router'

const getRoutes = () => {
  let routes
  let domain = window.location.host.toLowerCase().split('.').shift()

  switch(domain) {
    case 'ccu':
      routes = [{
        path: '/',
        name: 'CCU',
        component: () => import('@/views/CCU.vue'),
        meta: {
          title: 'CCU',
          favicon: 'mutual.png'
        }
      }]
      break

    case 'pasaporte':
      routes = [{
        path: '/',
        name: 'Passport',
        component: () => import('@/views/Passport.vue'),
        meta: {
          title: 'Pasaporte',
          favicon: 'passport.png'
        }
      }]   
      break
    
    case 'derco':
      routes = [{
        path: '/',
        name: 'Derco',
        component: () => import('@/views/Derco.vue'),
        meta: {
          title: 'Campus Derco',
          favicon: 'derco.png'
        }
      }]
      break

    case 'mutual':
      routes = [{
        path: '/',
        name: 'Mutual',
        component: () => import('@/views/Mutual.vue'),
        meta: {
          title: 'Mutual',
          favicon: 'mutual.png'
        }
      }]
      break

    case 'mutual2':
      routes = [{
        path: '/',
        name: 'Mutual',
        component: () => import('@/views/MutualCampaign.vue'),
        meta: {
          title: 'Mutual',
          favicon: 'mutual.png'
        }
      }]
      break

    case 'loanco':
      routes = [{
        path: '/',
        name: 'Loanco',
        component: () => import('@/views/Loanco.vue'),
        meta: {
          title: 'Loanco',
          favicon: 'loanco.png'
        }
      }]
      break
  
    default:
      routes = [{
        path: '/',
        name: 'Closelly',
        component: () => import('@/views/Closelly.vue'),
        meta: {
          title: 'Closelly',
          favicon: 'closelly.png'
        }
      }]
  }

  return routes
}

const router = createRouter({
  history: createWebHistory(),
  routes: getRoutes()
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`

  const favicon = document.querySelector('[rel=icon]')

  favicon.href = to.meta.favicon || 'closelly.png';

  next()
})

export default router